import PiwikProProvider from '@piwikpro/next-piwik-pro'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import '../i18n/i18n'

import CookiebotDeclarationProvider from '@contexts/CookiebotDeclaration'
import LoaderProvider from '@contexts/DataLoader'
import ModalProvider from '@contexts/Modal'
import ResponsiveProvider from '@contexts/Responsive'
import SubmenuBackgroundProvider from '@contexts/SubmenuBackground'
import ThemeProvider from '@contexts/Theme'

function ArcaApp({ Component, pageProps, nonce }) {
  return (
    <ThemeProvider>
      <ResponsiveProvider>
        <LoaderProvider>
          <ModalProvider>
            <SubmenuBackgroundProvider>
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_API_KEY}
                language='it'
                scriptProps={{
                  nonce: `nonce-${nonce}`,
                }}
              >
                <PiwikProProvider
                  containerId={process.env.NEXT_PUBLIC_PIWIK_PRO_CONTAINER_ID}
                  containerUrl={process.env.NEXT_PUBLIC_PIWIK_PRO_CONTAINER_URL}
                  nonce={`nonce-${nonce}`}
                >
                  <CookiebotDeclarationProvider nonce={nonce}>
                    <Component
                      {...pageProps}
                      nonce={nonce}
                    />
                  </CookiebotDeclarationProvider>
                </PiwikProProvider>
              </GoogleReCaptchaProvider>
            </SubmenuBackgroundProvider>
          </ModalProvider>
        </LoaderProvider>
      </ResponsiveProvider>
    </ThemeProvider>
  )
}

export default ArcaApp
