import localFont from 'next/font/local'

const metropolis = localFont({
  src: [
    {
      path: '../public/fonts/Metropolis-Light.woff2',
      weight: '300',
      style: 'normal',
      fallback: ['sans-serif'],
      display: 'swap',
      preload: true,
    },
    {
      path: '../public/fonts/Metropolis-LightItalic.woff2',
      weight: '300',
      style: 'italic',
      fallback: ['sans-serif'],
      display: 'swap',
      preload: true,
    },
    {
      path: '../public/fonts/Metropolis-Regular.woff2',
      weight: '400',
      style: 'normal',
      fallback: ['sans-serif'],
      display: 'swap',
      preload: true,
    },
    {
      path: '../public/fonts/Metropolis-RegularItalic.woff2',
      weight: '400',
      style: 'italic',
      fallback: ['sans-serif'],
      display: 'swap',
      preload: true,
    },
    {
      path: '../public/fonts/Metropolis-Medium.woff2',
      weight: '500',
      style: 'normal',
      fallback: ['sans-serif'],
      display: 'swap',
      preload: true,
    },
    {
      path: '../public/fonts/Metropolis-MediumItalic.woff2',
      weight: '500',
      style: 'italic',
      fallback: ['sans-serif'],
      display: 'swap',
      preload: true,
    },
    {
      path: '../public/fonts/Metropolis-Bold.woff2',
      weight: '700',
      style: 'normal',
      fallback: ['sans-serif'],
      display: 'swap',
      preload: true,
    },
    {
      path: '../public/fonts/Metropolis-ExtraBold.woff2',
      weight: '800',
      style: 'normal',
      fallback: ['sans-serif'],
      display: 'swap',
      preload: true,
    },
  ],
})

export default metropolis
