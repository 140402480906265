import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { default as translations } from './translations'

const detectionConfig = {
  order: ['path', 'cache'],
  lookupCookie: 'i18next',
  lookupFromPathIndex: 0,
  caches: ['cookie'],
  excludeCacheFor: ['cimode'],
  cookieMinutes: 10,
  cookieDomain: 'myDomain',
  cookieOptions: { path: '/', sameSite: 'strict' },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: detectionConfig,
    resources: translations,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
