import { createContext, useCallback, useEffect, useMemo, useRef } from 'react'

export const CookiebotDeclarationContext = createContext()

const CookiebotDeclarationProvider = ({ children, nonce }) => {
  const CookiebotDeclarationScript = useCallback(() => {
    return <CookibotDeclarationScriptComponent nonce={nonce} />
  }, [])
  return (
    <CookiebotDeclarationContext.Provider value={CookiebotDeclarationScript}>
      {children}
    </CookiebotDeclarationContext.Provider>
  )
}

function CookibotDeclarationScriptComponent({ nonce }) {
  const meoizedNonce = useMemo(() => nonce, [])
  const legalContainerRef = useRef(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'CookieDeclaration'
    script.src = `https://consent.cookiebot.com/${process.env.NEXT_PUBLIC_COOKIEBOT_ID}/cd.js`
    script.type = 'text/javascript'
    script.nonce = `nonce-${meoizedNonce}`
    script.async

    legalContainerRef.current.appendChild(script)

    return () => {
      // legalContainerRef.current.removeChild(script)
    }
  }, [legalContainerRef])

  return (
    <div
      ref={legalContainerRef}
      className='legal__container'
    />
  )
}

export default CookiebotDeclarationProvider
