import React, { createContext, useState, useEffect, useCallback } from 'react'

export const ModalContext = createContext({
  modalId: null,
  modalOpen: false,
  handleModalChange: () => {},
})

const ModalProvider = ({ children }) => {
  const [state, setState] = useState({
    modalId: null,
    modalOpen: false,
  })

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      const nextState = {
        modalId: null,
        modalOpen: false,
      }
      setState(nextState)
    }
  }, [])

  const handleModalChange = useCallback((opened, id) => {
    const nextState = {
      modalId: id,
      modalOpen: opened,
    }
    setState(nextState)

    if (typeof document !== 'undefined') {
      document.body.style.overflowY = !!opened ? 'hidden' : 'auto'
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <ModalContext.Provider
      value={{
        modalOpen: state.modalOpen,
        modalId: state.modalId,
        handleModalChange,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
