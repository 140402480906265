export default {
  // customGridConf: {},
  siteColumns: 12,
  ease: 'ease',
  timing: {
    fast: '0.2s',
    normal: '0.5s',
    slow: '1s',
    verySlow: '5s',
  },
  margins: {
    default: '3.2rem',
    large: '5.2rem',
  },
  defaults: {
    size: 8,
  },
  colors: {
    primary: {
      dark1: '#00264A',
      default: '#00305C',
      light1: '#19456C',
      light2: '#406485',
      light3: '#66839D',
      light4: '#CCD6DE',
      light5: '#E5EAEF',
      light6: '#F5F7F8',
    },
    secondary: {
      dark2: '#0064DC',
      dark1: '#0064D6',
      default: '#0070F0',
      light1: '#338DF3',
      light2: '#66A9F6',
      light3: '#99C6F9',
      light4: '#CCE2FC',
      light5: '#E5F1FD',
      light6: '#F5F9FE',
    },
    surface: {
      white: {
        background: '#FFFFFF',
        text: '#00305C',
        textHover: '#406485',
        tagline: '#0070F0',
        borderColor1: '#406485',
        borderColor2: '#CCD6DE',
        borderColor3: '#406485',
      },
      dark: {
        background: '#00305C',
        text: '#FFFFFF',
        textHover: '#F5F7F8',
        tagline: '#FFFFFF',
        borderColor1: '#CCD6DE',
        borderColor2: '#E5EAEF',
        borderColor3: '#FFFFFF',
      },
      primaryLight: {
        background: '#F5F7F8',
        text: '#00305C',
        textHover: '#406485',
        tagline: '#00305C',
        borderColor1: '#CCD6DE',
        borderColor2: '#E5EAEF',
        borderColor3: '#406485',
      },
      secondaryLight: {
        background: '#F5F9FE',
        text: '#00305C',
        textHover: '#406485',
        tagline: '#00305C',
        borderColor1: '#CCD6DE',
        borderColor2: '#E5EAEF',
        borderColor3: '#406485',
      },
    },
    button: {
      white: {
        primary: {
          base: {
            background: '#0064DC',
            color: '#FFFFFF',
            borderColor: '#0064DC',
          },
          hover: {
            background: '#0064D6',
            color: '#FFFFFF',
          },
          focus: {
            background: '#0064DC',
          },
          active: {
            background: '#0064D6',
            color: '#FFFFFF',
          },
        },
        secondary: {
          base: {
            background: '#FFFFFF',
            color: '#0064DC',
            borderColor: '#0064DC',
          },
          hover: {
            background: '#F5F9FE',
            color: '#0064DC',
          },
          focus: {
            background: '#F5F9FE',
          },
          active: {
            background: '#CCE2FC',
            color: '#0064DC',
          },
        },
        tertiary: {
          base: {
            background: 'transparent',
            color: '#0064DC',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#0064D6',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#0064D6',
          },
        },
        quaternary: {
          base: {
            background: 'transparent',
            color: '#CCD6DE',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#FFFFFF',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#FFFFFF',
          },
        },
        fifth: {
          base: {
            background: 'transparent',
            color: '#00305C',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#00305C',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#00305C',
          },
        },
      },
      dark: {
        primary: {
          base: {
            background: '#0064DC',
            color: '#FFFFFF',
            borderColor: '#0064DC',
          },
          hover: {
            background: '#0064D6',
            color: '#FFFFFF',
          },
          focus: {
            background: '#0064DC',
          },
          active: {
            background: '#0064D6',
            color: '#FFFFFF',
          },
        },
        secondary: {
          base: {
            background: '#FFFFFF',
            color: '#0064DC',
            borderColor: '#0064DC',
          },
          hover: {
            background: '#F5F9FE',
            color: '#0064DC',
          },
          focus: {
            background: '#F5F9FE',
          },
          active: {
            background: '#CCE2FC',
            color: '#0064DC',
          },
        },
        tertiary: {
          base: {
            background: 'transparent',
            color: '#F5F9FE',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#E5F1FD',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#E5F1FD',
          },
        },
        quaternary: {
          base: {
            background: 'transparent',
            color: '#CCD6DE',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#FFFFFF',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#FFFFFF',
          },
        },
        fifth: {
          base: {
            background: 'transparent',
            color: '#00305C',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#00305C',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#00305C',
          },
        },
      },
      primaryLight: {
        primary: {
          base: {
            background: '#0064DC',
            color: '#FFFFFF',
            borderColor: '#0064DC',
          },
          hover: {
            background: '#0064D6',
            color: '#FFFFFF',
          },
          focus: {
            background: '#0064DC',
          },
          active: {
            background: '#0064D6',
            color: '#FFFFFF',
          },
        },
        secondary: {
          base: {
            background: '#FFFFFF',
            color: '#0064DC',
            borderColor: '#0064DC',
          },
          hover: {
            background: '#F5F9FE',
            color: '#0064DC',
          },
          focus: {
            background: '#F5F9FE',
          },
          active: {
            background: '#CCE2FC',
            color: '#0064DC',
          },
        },
        tertiary: {
          base: {
            background: 'transparent',
            color: '#0064DC',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#0064D6',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#0064D6',
          },
        },
        quaternary: {
          base: {
            background: 'transparent',
            color: '#CCD6DE',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#FFFFFF',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#FFFFFF',
          },
        },
        fifth: {
          base: {
            background: 'transparent',
            color: '#00305C',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#00305C',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#00305C',
          },
        },
      },
      secondaryLight: {
        primary: {
          base: {
            background: '#0064DC',
            color: '#FFFFFF',
            borderColor: '#0064DC',
          },
          hover: {
            background: '#0064D6',
            color: '#FFFFFF',
          },
          focus: {
            background: '#0064DC',
          },
          active: {
            background: '#0064D6',
            color: '#FFFFFF',
          },
        },
        secondary: {
          base: {
            background: '#FFFFFF',
            color: '#0064DC',
            borderColor: '#0064DC',
          },
          hover: {
            background: '#F5F9FE',
            color: '#0064DC',
          },
          focus: {
            background: '#F5F9FE',
          },
          active: {
            background: '#CCE2FC',
            color: '#0064DC',
          },
        },
        tertiary: {
          base: {
            background: 'transparent',
            color: '#0064DC',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#0064D6',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#0064D6',
          },
        },
        quaternary: {
          base: {
            background: 'transparent',
            color: '#CCD6DE',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#FFFFFF',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#FFFFFF',
          },
        },
        fifth: {
          base: {
            background: 'transparent',
            color: '#00305C',
            borderColor: 'transparent',
          },
          hover: {
            background: 'transparent',
            color: '#00305C',
          },
          focus: {
            background: 'transparent',
          },
          active: {
            background: 'transparent',
            color: '#00305C',
          },
        },
      },
    },
    grayscale: {
      white: '#FFFFFF',
    },
    status: {
      success: {
        dark1: '#2B774E',
        default: '#44BA79',
        light1: '#DAF1E4',
      },
      warning: {
        dark1: '#A27632',
        default: '#FCB84F',
        light1: '#FEF1DC',
      },
      error: {
        dark1: '#99392B',
        default: '#C9331E',
        light1: '#FCDEDA',
      },
    },
    brand: {
      purple: {
        light1: '#E0E7F5',
        default: '#284383',
      },
      yellow: {
        light1: '#FFE7AC',
        default: '#E1BE46',
      },
      orange: {
        light1: '#F8B999',
        default: '#E84C0F',
      },
      green: {
        light1: '#D6EBE2',
        default: '#007C55',
      },
      teal: {
        light1: '#DBECF6',
        default: '#10A7D4',
      },
      grey: {
        light1: '#D1D7DA',
        default: '#9DB0BB',
      },
    },
  },
  shadows: {
    type1:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    type2:
      '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    type3:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    type4:
      '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  },
  typography: {
    display3XL: {
      'font-size': 72,
      'line-height': 80,
      mq: {
        lg: {
          'font-size': 104,
          'line-height': 112,
        },
      },
    },
    display2XL: {
      'font-size': 60,
      'line-height': 72,
      mq: {
        lg: {
          'font-size': 72,
          'line-height': 80,
        },
      },
    },
    displayXL: {
      'font-size': 48,
      'line-height': 54,
      mq: {
        lg: {
          'font-size': 60,
          'line-height': 72,
        },
      },
    },
    displayLG: {
      'font-size': 40,
      'line-height': 48,
      mq: {
        lg: {
          'font-size': 48,
          'line-height': 54,
        },
      },
    },
    displayMD: {
      'font-size': 32,
      'line-height': 40,
      mq: {
        lg: {
          'font-size': 40,
          'line-height': 48,
        },
      },
    },
    displaySM: {
      'font-size': 24,
      'line-height': 32,
      mq: {
        lg: {
          'font-size': 32,
          'line-height': 40,
        },
      },
    },
    displayXS: {
      'font-size': 24,
      'line-height': 32,
      mq: {
        lg: {
          'font-size': 20,
          'line-height': 28,
        },
      },
    },
    paragraph1: {
      'font-size': 16,
      'line-height': 24,
    },
    paragraph2: {
      'font-size': 14,
      'line-height': 20,
    },
    paragraphLG: {
      'font-size': 18,
      'line-height': 28,
    },
    button1: {
      'font-size': 13,
      'line-height': 24,
    },
    button2: {
      'font-size': 12,
      'line-height': 16,
    },
    link1: {
      'font-size': 12,
      'line-height': 16,
    },
    label1: {
      'font-size': 13,
      'line-height': 16,
    },
    label2: {
      'font-size': 12,
      'line-height': 12,
    },
    inputPlaceholder1: {
      'font-size': 12,
      'line-height': 14,
      mq: {
        lg: {
          'font-size': 14,
          'line-height': 16,
        },
      },
    },
    inputValue1: {
      'font-size': 12,
      'line-height': 14,
      mq: {
        lg: {
          'font-size': 14,
          'line-height': 16,
        },
      },
    },
  },
  layers: {
    loaderData: 30,
    modal: 25,
    header: 21,
    myQuoteBar: 20,
    submenu: 15,
    formMessage: 21,
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
}
