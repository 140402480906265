import { createGlobalStyle } from 'styled-components'

import metropolis from '@theme/fonts'

import swiperGlobal from 'swiper/css'
import swiperScrollbar from 'swiper/css/scrollbar'
import swiperMousewheel from 'swiper/css/mousewheel'
import swiperNavigation from 'swiper/css/navigation'

const GlobalStyles = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    border: 0;
    ${metropolis.style}
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  picture {
    display: block;
  }

  body {
    line-height: 1;
    overscroll-behavior-y: none;
  }

  ol,
  ul {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
  }

  ::-webkit-input-placeholder {
    ${({ theme }) => theme.typo('inputPlaceholder1')};
    color: ${({ theme }) => theme.colors.primary.light2};
  }
  ::-moz-placeholder {
    ${({ theme }) => theme.typo('inputPlaceholder1')};
    color: ${({ theme }) => theme.colors.primary.light2};
  }
  :-ms-input-placeholder {
    ${({ theme }) => theme.typo('inputPlaceholder1')};
    color: ${({ theme }) => theme.colors.primary.light2};
  }
  :-moz-placeholder {
    ${({ theme }) => theme.typo('inputPlaceholder1')};
    color: ${({ theme }) => theme.colors.primary.light2};
  }

  // Basic Styles
  html {
    width: 100%;
    ${metropolis.style}
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary.default};
    box-sizing: border-box;
    
    *, *:before, *:after {
      box-sizing: border-box;
    }
  }

  body {
    width: 100%;
    overflow-x: hidden;
  }

  ${swiperGlobal}
  ${swiperScrollbar}
  ${swiperMousewheel}
  ${swiperNavigation}

  .grecaptcha-badge {
    visibility: hidden;
  }

  .highlighted {
    color: ${({ theme }) => theme.colors.secondary.dark2};
  }

  .scroll-lock {
    overflow: hidden !important;
  }

  .sr-only {
    width: 1px;
    height: 1px;
    position: absolute;
    margin: -1px;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
  }
`
export default GlobalStyles
