import theme from './config'

const processCss = (css) => {
  let injectedCss = ''
  if (typeof css === 'string') return css
  if (typeof css.raw === 'object') return css.raw[0]
  for (const [key, value] of Object.entries(css)) {
    let processedValue = value
    if (key === 'font-size' || key === 'line-height')
      processedValue = rem(value)
    injectedCss += `${key}: ${processedValue}; `
  }

  return injectedCss
}

export let mediaQuery = {}

for (const key in theme.breakpoints) {
  mediaQuery[key] = function (css, until = false) {
    let query = until ? 'max-width' : 'min-width'
    return `
      @media screen and (${query}: ${theme.breakpoints[key]}px) {
        ${processCss(css)}
      }
    `
  }
}

export function rem(...values) {
  return values.map((px) => `${parseInt(px) / 16}rem`).join(' ')
}

export function col(...values) {
  return values.map((col) => `calc(${col} / ${theme.siteColumns} * 100vw);`)
}

export function color(key, variant = 'default') {
  if (!theme.colors[key]) return 'inherit'
  return theme.colors[key][variant]
    ? theme.colors[key][variant]
    : theme.colors[key]
}

export function spacing(scalar = 1) {
  return rem(Number(scalar) * theme.defaults.size)
}

export function typo(key) {
  let rules = []

  if (theme.typography[key]) {
    for (const [rule, ruleValue] of Object.entries(theme.typography[key])) {
      if (rule === 'mq') {
        for (const [mq, mqValue] of Object.entries(ruleValue)) {
          rules.push(mediaQuery[mq](mqValue))
        }
      } else {
        let processedValue = ruleValue
        if (rule === 'font-size' || rule === 'line-height')
          processedValue = rem(ruleValue)
        rules.push(`${rule}: ${processedValue};`)
      }
    }

    return [...rules]
  }
}

export const checkBreakpoint = {
  xs: ({ width }) => width >= theme.breakpoints.xs,
  sm: ({ width }) => width >= theme.breakpoints.sm,
  md: ({ width }) => width >= theme.breakpoints.md,
  lg: ({ width }) => width >= theme.breakpoints.lg,
  xl: ({ width }) => width >= theme.breakpoints.xl,
  xxl: ({ width }) => width >= theme.breakpoints.xxl,
  not: {
    xs: ({ width }) => width < theme.breakpoints.xs,
    sm: ({ width }) => width < theme.breakpoints.sm,
    md: ({ width }) => width < theme.breakpoints.md,
    lg: ({ width }) => width < theme.breakpoints.lg,
    xl: ({ width }) => width < theme.breakpoints.xl,
    xxl: ({ width }) => width < theme.breakpoints.xxl,
  },
}
