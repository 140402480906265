import React, { createContext, useCallback, useEffect, useState } from 'react'

export const SubmenuBackgroundContext = createContext({
  height: 0,
  setSubmenuBackgroundHeight: () => {},
})

const SubmenuBackgroundProvider = ({ children }) => {
  const [height, setHeight] = useState(0)

  const setSubmenuBackgroundHeight = useCallback((height) => {
    setHeight(height)
  }, [])

  return (
    <SubmenuBackgroundContext.Provider
      value={{
        height,
        setSubmenuBackgroundHeight,
      }}
    >
      {children}
    </SubmenuBackgroundContext.Provider>
  )
}

export default SubmenuBackgroundProvider
